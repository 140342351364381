import { useEffect, useState } from 'react'

export const useDateTime = () => {
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date()
            const timeFormatter = new Intl.DateTimeFormat('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            })
            const dateFormatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
            })

            const formattedTime = timeFormatter.format(now)
            const formattedDate = dateFormatter.format(now)

            setDate(`${formattedDate}`)
            setTime(`${formattedTime}`)
        }

        updateDateTime()
        const intervalId = setInterval(updateDateTime, 1000)

        return () => clearInterval(intervalId)
    }, [])
    return { date, time }
}
