import Header from 'components/Headers/Header'
import React from 'react'
import { Add, Delete, Event as EventIcon, Launch } from '@material-ui/icons'
import {
    Container,
    Box,
    Typography,
    Button,
    IconButton,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    CardActions,
} from '@material-ui/core'
import RemoteServices from 'RemoteServices/RemoteServices'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

const TeacherSupport = () => {
    const [meetings, setMeetings] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [refetch, setRefetch] = React.useState(false)

    React.useEffect(() => {
        async function fetchMeetings() {
            try {
                setIsLoading(true)
                const data = await RemoteServices.getMeetingLinks()
                if (data) {
                    setMeetings(data)
                }
            } catch (err) {
                console.log(err)
            } finally {
                setIsLoading(false)
                setRefetch(false)
            }
        }
        fetchMeetings()
    }, [refetch])

    return (
        <>
            <Header page={'lesson'} />
            <MeetingList
                {...{
                    meetings,
                    isLoading,
                    setRefetch,
                }}
            />
        </>
    )
}

const EmptyMeetingsScreen = ({ createNewMeeting }) => {
    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '80dvh',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <IconButton color="primary">
                    <EventIcon
                        style={{
                            fontSize: 80,
                            mb: 2,
                        }}
                    />
                </IconButton>

                <Typography variant="h4" component="h1" gutterBottom>
                    No Meetings Yet
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                    You haven't created any meetings. Start by creating your
                    first meeting!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<EventIcon />}
                    onClick={createNewMeeting}
                >
                    Create New Meeting
                </Button>
            </Box>
        </Container>
    )
}

function MeetingList({ meetings, isLoading, setRefetch }) {
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
    const [newMeetingLoading, setNewMeetingLoading] = React.useState(false)
    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    const createNewMeeting = async () => {
        try {
            setNewMeetingLoading(true)
            const segment = () => Math.random().toString(36).substring(2, 5)
            const meetingId = `${segment()}-${segment()}-${segment()}`
            await RemoteServices.postNewMeeting({ meeting_id: meetingId })
            enqueueSnackbar(`New meeting added successfully!`, {
                variant: 'success',
            })
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        } finally {
            setNewMeetingLoading(false)
            setRefetch(true)
        }
    }

    const deleteMeeting = async (id) => {
        try {
            setIsDeleteLoading(true)
            await RemoteServices.deleteMeeting(id)
            enqueueSnackbar(`Meeting #${id} deleted successfully!`, {
                variant: 'success',
            })
        } catch (err) {
            console.log('Unable to delete meeting', err)
        } finally {
            setIsDeleteLoading(false)
            setRefetch(true)
        }
    }

    const handleMeetingRedirect = (meetingId) => {
        history.push(`/admin/group-call?ready=${meetingId}`)
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 4,
                    }}
                >
                    <Typography variant="h4" component="h1" gutterBottom>
                        Your Meetings
                    </Typography>

                    <Button
                        variant="contained"
                        disabled={newMeetingLoading}
                        color="primary"
                        startIcon={<Add />}
                        onClick={createNewMeeting}
                    >
                        {newMeetingLoading ? (
                            <CircularProgress size={8} />
                        ) : null}
                        Create New Meeting
                    </Button>
                </Box>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </Box>
                ) : !meetings || meetings.length === 0 ? (
                    <EmptyMeetingsScreen {...{ createNewMeeting }} />
                ) : (
                    <Grid container spacing={3}>
                        {meetings.map((meeting) => (
                            <Grid item xs={12} sm={6} md={4} key={meeting.id}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            gutterBottom
                                        >
                                            Meeting #{meeting.id}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            ID: {meeting.meeting_id}
                                        </Typography>
                                    </CardContent>
                                    <CardActions
                                        sx={{ justifyContent: 'flex-end' }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<Launch />}
                                            onClick={() =>
                                                handleMeetingRedirect(
                                                    meeting.meeting_id
                                                )
                                            }
                                        >
                                            Join Meeting
                                        </Button>

                                        <IconButton
                                            aria-label="delete meeting"
                                            onClick={() =>
                                                deleteMeeting(meeting.id)
                                            }
                                            size="small"
                                            disabled={isDeleteLoading}
                                            color="secondary"
                                        >
                                            {isDeleteLoading ? (
                                                <CircularProgress size={8} />
                                            ) : (
                                                <Delete />
                                            )}
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Container>
    )
}

export default TeacherSupport
