import React, { useContext, useEffect, useState } from 'react'
import { SocketContext } from 'layouts/Socket'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { PeerConnection } from '../../utils/video-call/PeerConnection'
import MainWindow from '../../components/VideoCall/MainWindow'
import CallWindow from '../../components/VideoCall/CallWindow'
import CallModal from '../../components/VideoCall/CallModal'
import { socketListen } from 'utils/video-call/SocketListener'

const VideoCall = (props) => {
    const [msg, setMessage] = useState('Hell')
    const [callWindow, setCallWindow] = useState('')
    const [callModal, setCallModal] = useState('')
    const [callFrom, setCallFrom] = useState('')
    const [localSrc, setLocalSrc] = useState(null)
    const [peerSrc, setPeerSrc] = useState(null)

    const [pc, setPc] = useState({})
    const [config, setConfig] = useState(null)

    const { socket } = useContext(SocketContext)
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const userId = queryParams.get('user-id')

    const meId = JSON.parse(localStorage.getItem('user'))?.id
    const full_name = JSON.parse(localStorage.getItem('user'))?.full_name

    useEffect(() => {
        const listener = socketListen(socket)
        listener.on('call', 'request', 'admin', (res) => {
            const { from } = res
            setCallModal('active')
            setCallFrom(from)
            console.log('requested from socket', res)
        })
        listener.on('call', 'call', 'admin', (res) => {
            if (res.sdp) {
                pc.setRemoteDescription(res.sdp)
                if (res.sdp.type === 'offer') pc.createAnswer()
            } else pc.addIceCandidate(res.candidate)
        })
        listener.on('call', 'end', 'admin', (res) => {
            endCall(res.isStarter)
        })
        socket.send(
            JSON.stringify({
                action: 'call',
                action_type: 'init',
                clientId: 123,
                calling_to: '',
                user_id: userId,
                me_id: meId,
                full_name: full_name,
            })
        )
    }, [socket, pc, meId])

    const startCall = (isCaller, callFrom, config) => {
        const conxn = new PeerConnection(socket, userId)
            .on('localStream', (src) => {
                setCallWindow('active')
                setLocalSrc(src)
                if (!isCaller) {
                    setCallModal('')
                }
            })
            .on('peerStream', (src) => setPeerSrc(src))
            .start(isCaller)

        setPc(conxn)
        setConfig(config)
    }

    const rejectCall = () => {
        socket.send(
            JSON.stringify({
                action: 'call',
                action_type: 'end',
                to: callFrom,
                calling_to: 'user',
                user_id: userId,
                me_id: meId,
                full_name: full_name,
            })
        )
        setCallModal('')
    }

    const endCall = (isStarter) => {
        if (_.isFunction(pc.stop)) {
            pc.stop(isStarter)
        }
        setPc({})
        setConfig(null)
        setCallModal('')
        setCallWindow('')
        setLocalSrc(null)
        setPeerSrc(null)
    }

    return (
        <>
            <div>
                {_.isEmpty(config) && <MainWindow startCall={startCall} />}

                {!_.isEmpty(config) && (
                    <CallWindow
                        status={callWindow}
                        localSrc={localSrc}
                        peerSrc={peerSrc}
                        config={config}
                        pc={pc}
                        mediaDevice={pc.mediaDevice}
                        endCall={endCall}
                    />
                )}
                <CallModal
                    status={callModal}
                    startCall={startCall}
                    rejectCall={rejectCall}
                    callFrom={callFrom}
                />
            </div>
        </>
    )
}

export default VideoCall
