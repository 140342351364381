const axios = require('axios')
const { refreshAccessToken } = require('./RefershAccessToken')

const remote = {
    address: process.env.REACT_APP_BASE_URL,
}

function getRequests(api) {
    let token = localStorage.getItem('token')
    const apiUrl = remote.address + api
    let promise = new Promise((resolve, reject) => {
        axios
            .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data
                resolve(data)
            })
            .catch((error) => {
                if (
                    error.response.status === 401 &&
                    error.response.data.code === 'token_not_valid'
                ) {
                    refreshAccessToken()
                }
                var errorMessage =
                    error.response.data.Message ||
                    error.response.data ||
                    error.response
                reject(errorMessage)
            })
    })
    return promise
}

function getLinkRequests(api) {
    let token = localStorage.getItem('token')
    const apiUrl = api
    let promise = new Promise((resolve, reject) => {
        axios
            .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data
                resolve(data)
            })
            .catch((error) => {
                if (
                    error.response.status === 401 &&
                    error.response.data.code === 'token_not_valid'
                ) {
                    refreshAccessToken()
                }
                var errorMessage =
                    error.response.data.Message ||
                    error.response.data ||
                    error.response
                reject(errorMessage)
            })
    })
    return promise
}

function postRequests(api, data) {
    let token = localStorage.getItem('token')
    const apiUrl = remote.address + api
    let promise = new Promise((resolve, reject) => {
        axios
            .post(apiUrl, data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                var data = res.data
                resolve(data)
            })
            .catch((error) => {
                var errorMessage =
                    error.response.data.Message ||
                    error.response.data ||
                    error.response
                reject(errorMessage)
            })
    })
    return promise
}

function patchRequests(api, data) {
    let token = localStorage.getItem('token')
    const apiUrl = remote.address + api
    let promise = new Promise((resolve, reject) => {
        axios
            .patch(apiUrl, data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                var data = res.data
                resolve(data)
            })
            .catch((error) => {
                var errorMessage =
                    error.response.data.Message ||
                    error.response.data ||
                    error.response
                reject(errorMessage)
            })
    })
    return promise
}

function deleteRequests(api) {
    let token = localStorage.getItem('token')
    const apiUrl = remote.address + api
    let promise = new Promise((resolve, reject) => {
        axios
            .delete(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                var data = res.data
                resolve(data)
            })
            .catch((error) => {
                var errorMessage =
                    error.response.data.Message ||
                    error.response.data ||
                    error.response
                reject(errorMessage)
            })
    })
    return promise
}

function putRequests(api, data) {
    let token = localStorage.getItem('token')
    const apiUrl = remote.address + api
    let promise = new Promise((resolve, reject) => {
        axios
            .put(apiUrl, data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                var data = res.data
                resolve(data)
            })
            .catch((error) => {
                var errorMessage =
                    error.response.data.Message ||
                    error.response.data ||
                    error.response
                reject(errorMessage)
            })
    })
    return promise
}

var RemoteServices = {
    sendLeads: function (data) {
        const url = 'students/leads/list/'
        return postRequests(url, data)
    },
    sendLogin: function (data) {
        const url = 'user/get-token/'
        return postRequests(url, data)
    },
    createStudentNotes: function (data) {
        const url = 'students/studentnotes/list/'
        return postRequests(url, data)
    },
    createPromoCode: function (data) {
        const url = 'user/promocode/list/'
        return postRequests(url, data)
    },
    getStudentNotes: function (data) {
        const url = `students/studentnotes/list/?student=${data}`
        return getRequests(url)
    },

    getPromoCode: function (data) {
        const url = `user/promocode/list/?user=${data}`
        return getRequests(url)
    },

    getVerifiedEmail: function () {
        const url = `user/email/`
        return getRequests(url)
    },
    getPromoCodes: function (data) {
        const url = `user/promocode/list/`
        return getRequests(url)
    },

    getCourses: function (data) {
        const url = 'courses/'
        return getRequests(url)
    },
    deleteCourse: function (id) {
        const url = 'courses/' + id + '/'
        return deleteRequests(url)
    },
    createCourse: function (data) {
        const url = 'courses/'
        return postRequests(url, data)
    },

    postHierarchy: function (id, data) {
        const url = `hirerchy/user-hirerchy/${id}`
        return postRequests(url, data)
    },

    updateCourse: function (id, data) {
        const url = 'courses/' + id + '/'
        return patchRequests(url, data)
    },

    updateTeacherSupport: function (id, data) {
        const url = 'user/meet/list/' + id + '/'
        return patchRequests(url, data)
    },

    addTeacherSupport: function (data) {
        const url = 'user/meet/list/'
        return postRequests(url, data)
    },
    getTeacherSupport: function () {
        const url = 'user/meet/list/'
        return getRequests(url)
    },
    getLessonList: function (id) {
        const url = id ? `lessons/?course=${id}&ordering=order` : 'lessons/'
        return getRequests(url)
    },
    updateLesson: function (lessonId, data) {
        const url = 'lessons/' + lessonId + '/'
        return patchRequests(url, data)
    },
    updateFreeLesson: function (lessonId, data) {
        const url = 'lessons/freelesson/list/' + lessonId + '/'
        return patchRequests(url, data)
    },
    deleteLesson: function (lessonId) {
        const url = 'lessons/' + lessonId + '/'
        return deleteRequests(url)
    },
    deleteFreeLesson: function (lessonId) {
        const url = 'lessons/freelesson/list/' + lessonId + '/'
        return deleteRequests(url)
    },
    createLesson: function (courseId, data) {
        const url = 'lessons/'
        return postRequests(url, data)
    },
    createFreeLesson: function (data) {
        const url = 'lessons/freelesson/list/'
        return postRequests(url, data)
    },
    getAssignmentList: function (courseId) {
        const url = 'students/assigment/'
        return getRequests(url)
    },
    getPendingAssignments: function (courseId, urls) {
        const url = courseId
            ? 'students/assigment/?status=Pending&student_course_course=' +
              courseId
            : urls
        return getRequests(url)
    },
    getApprovedAssignments: function (courseId, urls) {
        const url = courseId
            ? 'students/assigment/?status=Approved&student_course_course=' +
              courseId
            : urls
        return getRequests(url)
    },
    getDeniedAssignments: function (courseId, urls) {
        const url = courseId
            ? 'students/assigment/?status=Denied&student_course_course=' +
              courseId
            : urls
        return getRequests(url)
    },
    updateAssignment: function (assignmentId, data) {
        const url = 'students/assigment/' + assignmentId + '/'
        return patchRequests(url, data)
    },
    getStudents: function (limit) {
        const url = `students/?limit=${limit}&offset=0&ordering=-user__created_at`
        return getRequests(url)
    },
    getStudentsDetails: function (id) {
        const url = 'students/' + id + '/'
        return getRequests(url)
    },
    getStudentDetailsNew: function (id) {
        const url = `students/?new_id=${id}`
        return getRequests(url)
    },

    getStudentWallet: function (id) {
        const url = `hirerchy/user-wallet/${id}`
        return postRequests(url)
    },
    createStudent: function (data) {
        const url = 'user/'
        return postRequests(url, data)
    },
    deleteStudent: function (id) {
        const url = `students/${id}`
        return deleteRequests(url)
    },
    getRequestedStudents: function (data) {
        const url = 'students/?course_requested=true'
        return getRequests(url)
    },
    getPaidStudents: function (data) {
        const url = 'students/?limit=20&offset=10&payment_status=Paid'
        return getRequests(url)
    },
    getActiveReferral: function (data) {
        const url = 'user/active_referred/'
        return getRequests(url)
    },
    getReferralStats: function () {
        const url = `hirerchy/admin-stats`
        return postRequests(url)
    },
    getPromoCodeStats: function () {
        const url = `user/promocode/list/promocode_stats/`
        return getRequests(url)
    },
    getHasReferralsStudents: function (data) {
        const url = 'students/?user__referrer__isnull=false'
        return getRequests(url)
    },
    getYoutubeUsers: function (data) {
        const url = 'students/?group=youtuber'
        return getRequests(url)
    },
    getTestUsers: function (data) {
        const url = 'students/?group=test'
        return getRequests(url)
    },
    getDueUsers: function (limit) {
        const url = `students/?limit=${limit}&offset=0payment_status=Due`
        return getRequests(url)
    },
    getLeadList: function (limit, stage, search, isNew) {
        console.log('asdfasdf', search)
        let url = ''
        if (isNew === 'new') {
            url = `leads/leads-lists/?limit=${limit}&stage=${stage}&search=${search}&order-by=${'new'}`
        } else {
            url = `leads/leads-lists/?limit=${limit}&stage=${stage}&search=${search}`
        }
        return getRequests(url)
    },
    getLeadDetail: function (id) {
        const url = `leads/${id}/leads-details/`
        return getRequests(url)
    },
    getNonYoutubeUsers: function (data) {
        const url = 'students/?group=student'
        return getRequests(url)
    },
    getOnlineStudent: function (data) {
        const url = 'students/?user__is_online=true'
        return getRequests(url)
    },
    updateStudent: function (id, data) {
        const url = 'students/' + id + '/'
        return patchRequests(url, data)
    },
    updateUserAmount: function (id, data) {
        const url = 'user/' + id + '/amount_update/'
        return patchRequests(url, data)
    },
    getStaffs: function (data) {
        const url = 'user/?is_staff=True'
        return getRequests(url, data)
    },

    // getUserList: function () {
    //     const url = 'user/'
    //     return getRequests(url)
    // },

    updateStaff: function (id, data) {
        const url = 'user/' + id + '/'
        return patchRequests(url, data)
    },
    createUser: function (data) {
        const url = 'user/'
        return postRequests(url, data)
    },
    deleteStaff: function (id) {
        const url = 'user/' + id + '/'
        return deleteRequests(url)
    },
    createArticle: function (data) {
        const url = 'articles/'
        return postRequests(url, data)
    },
    getArticles: function (data) {
        const url = 'articles/'
        return getRequests(url)
    },
    updateArticle: function (id, data) {
        const url = `articles/${id}/`
        return patchRequests(url, data)
    },
    deleteArticle: function (id) {
        const url = 'articles/' + id + '/'
        return deleteRequests(url)
    },
    updatePassword: function (id, data) {
        const url = 'user/' + id + '/password_change/'
        return putRequests(url, data)
    },

    updatePromoCode: function (id, data) {
        const url = 'user/promocode/list/' + id + '/'
        return patchRequests(url, data)
    },
    getDefaultLinks: function (data) {
        const url = 'default-links/'
        return getRequests(url)
    },
    deleteDefaultLink: function (id) {
        const url = 'default-links/' + id + '/'
        return deleteRequests(url)
    },
    updateDefaultLink: function (id, data) {
        const url = 'default-links/' + id + '/'
        return patchRequests(url, data)
    },
    getDashboardData: function () {
        const url = 'user/stats/'
        return getRequests(url)
    },
    getMonthlyDashboardData: function () {
        const url = 'user/monthly-stats/'
        return getRequests(url)
    },
    getWeeklyDashboardData: function () {
        const url = 'user/weekly-stats/'
        return getRequests(url)
    },
    getAssignmentsDashboardData: function () {
        const url = 'user/assignment-stats/'
        return getRequests(url)
    },
    getCustom: function (api) {
        console.log(api)
        return getRequests(api)
    },
    getLinkCustom: function (api) {
        return getLinkRequests(api)
    },
    getFreeCustom: function (api) {
        console.log(api)
        return getRequests(api)
    },
    getStudentsSearch: function (search, limit) {
        const url = `students/?limit=${limit}&search=` + search
        return getRequests(url)
    },

    getTotalAndPercentage: function () {
        const url = 'user/amountandpercentage/list/'
        return getRequests(url)
    },
    updateTotalAndPercentage: function (id, data) {
        const url = 'user/amountandpercentage/list/' + id + '/'
        return patchRequests(url, data)
    },

    getStudentsWithSearchType: function (type, searchText) {
        const url = `students/?${type}=${searchText}`
        return getRequests(url)
    },

    getDeactivatedUsersWithSearchType: function (type, searchText) {
        const url = `user/deleted_users/?${type}=${searchText}`
        return getRequests(url)
    },

    getStudentsWithDate: function (fromDate, toDate) {
        const url = `students/?limit=50&created_from=${toDate}&created_to=${fromDate}&ordering=-user__created_at`
        return getRequests(url)
    },
    createSmsCampaign: function (data, str) {
        const url = 'campaign/send-sms/' + str
        return postRequests(url, data)
    },
    createEmailCampaign: function (data, str) {
        const url = 'campaign/send-email/' + str
        return postRequests(url, data)
    },
    createNotificationCampaign: function (data, str) {
        const url = 'campaign/send-notification/' + str
        return postRequests(url, data)
    },
    sendLeadsNotification: function (data) {
        const url = 'leads/post-leads-content/'
        return postRequests(url, data)
    },
    sendLeadsPost: function (data) {
        const url = 'leads/post-leads/'
        return postRequests(url, data)
    },
    getLogData: function (before, after) {
        const url = `campaign/logs/?create_at_before=${before}&create_at_after=${after}`
        return getRequests(url)
    },
    getOnlySms: function (search) {
        const url = 'campaign/logs/?type=sms'
        return getRequests(url)
    },
    getOnlyEmail: function (search) {
        const url = 'campaign/logs/?type=email'
        return getRequests(url)
    },
    getOnlyNotifications: function (search) {
        const url = 'campaign/logs/?type=push'
        return getRequests(url)
    },
    getOnlySuccess: function (search) {
        const url = 'campaign/logs/?status=success'
        return getRequests(url)
    },
    getOnlyFailed: function (search) {
        const url = 'campaign/logs/?status=failed'
        return getRequests(url)
    },
    getAssignmentListHistory: function (id) {
        const url =
            'students/assigment/?limit=300&offset=0&student_course__student=' +
            id
        return getRequests(url)
    },
    updateCurrentLesson: function (data) {
        const url = 'students/course/set-lesson/'
        return postRequests(url, data)
    },
    getWithdrawList: function (status) {
        const url = 'students/payment-requests?status=' + status
        return getRequests(url)
    },
    updateWithdrawStatus: function (id, data) {
        const url = 'students/payment-requests/' + id + '/'
        return patchRequests(url, data)
    },
    getCourseLessons: function (courseId) {
        const url = `lessons/?course=${courseId}&ordering=order`
        return getRequests(url)
    },
    filterByCourseLesson: function (courseId, lessonId) {
        const url = `students/?course=${courseId}&lesson=${lessonId}&limit=50&offset=10`
        return getRequests(url)
    },
    createExchangeToken: function (data) {
        const url = 'exchange_token/facebook/'
        return postRequests(url, data)
    },
    getLessonRemarks: function (lessonId) {
        const url = `lessons/all/remarks/?lesson=${lessonId}`
        return getRequests(url)
    },
    addLessonQuiz: function (quizData) {
        const url = `quiz/`
        return postRequests(url, quizData)
    },
    addLessonFreeQuiz: function (quizData) {
        const url = `quiz/freequiz/`
        return postRequests(url, quizData)
    },
    getLessonQuiz: function (lesson, course) {
        const url = `quiz/?id=&lesson=${lesson}&course=${course}`
        return getRequests(url)
    },
    getFreeLessonQuiz: function (lesson, course) {
        const url = `quiz/freequiz/?id=&lesson=${lesson}`
        return getRequests(url)
    },
    updateLessonFreeQuiz: function (quizData) {
        const url = 'quiz/freequiz/update/'
        return postRequests(url, quizData)
    },
    updateLessonQuiz: function (quizData) {
        const url = 'quiz/update/'
        return postRequests(url, quizData)
    },
    getLessonComments: function (publishStatus) {
        const url = `lessons/reviews/list/?lesson=&ordering=-created_at&publish=${publishStatus}`
        return getRequests(url)
    },
    getFreeLessonComments: function (publishStatus) {
        const url = `lessons/freereviews/list/?lesson=&ordering=&publish=${publishStatus}`
        return getRequests(url)
    },

    getDeactiveRequestUser: function (publishStatus) {
        const url = `user/delete_request/`
        return getRequests(url)
    },

    getDeactivatedUser: function (publishStatus) {
        const url = `user/deactivated_users/`
        return getRequests(url)
    },

    reactivateUser: function (data) {
        const url = 'user/reactive_user/'
        return postRequests(url, data)
    },

    publishComment: function (args) {
        let putData = {
            message: args.message,
            publish: args.publish,
            lesson: args.lesson,
            user: args.user,
        }
        const url = `lessons/reviews/list/${args.commentId}/`
        return patchRequests(url, putData)
    },
    publishFreeComment: function (args) {
        let putData = {
            message: args.message,
            publish: args.publish,
            lesson: args.lesson,
            user: args.user,
        }
        const url = `lessons/freereviews/list/${args.commentId}/`
        return putRequests(url, putData)
    },
    deleteComment: function (commentId) {
        const url = `lessons/reviews/list/${commentId}/`
        return deleteRequests(url)
    },
    deleteFreeComment: function (commentId) {
        const url = `lessons/freereviews/list/${commentId}/`
        return deleteRequests(url)
    },
    deleteCommentReply: function (replyId) {
        const url = `lessons/reviews/list/${replyId}/reply/`
        return deleteRequests(url)
    },
    deleteFreeCommentReply: function (replyId) {
        const url = `lessons/freereviews/list/${replyId}/reply/`
        return deleteRequests(url)
    },
    verifyMasterPassword: function (args) {
        const url = `user/verify-master-password/`
        return postRequests(url, args)
    },
    changeMasterPassword: function (args) {
        const url = `user/master-password_change/`
        return postRequests(url, args)
    },
    getAppRatings: function (publishStatus) {
        const url = `app/review/?ordering=-created_at&publish=${publishStatus}`
        return getRequests(url)
    },
    publishAppRating: function (args) {
        let patchData = {
            message: args.message,
            publish: args.publish,
            rating: args.rating,
        }
        const url = `app/review/${args.ratingId}/`
        return patchRequests(url, patchData)
    },
    deleteAppRating: function (ratingId) {
        const url = `app/review/${ratingId}/`
        return deleteRequests(url)
    },
    deleteAppRatingReply: function (replyId) {
        const url = `app/review/${replyId}/reply-delete/`
        return deleteRequests(url)
    },
    getPaymentHistory: function () {
        const url = `user/payment-history/?approved=false`
        return getRequests(url)
    },
    approveStudentBill: function (data) {
        const url = `user/assign-bill/`
        return postRequests(url, data)
    },
    getBillHistory: function () {
        const url = `user/bill-history`
        return getRequests(url)
    },
    getCommunityPost: function (postsLimit) {
        const url = `community/?limit=${postsLimit}&ordering=-created_at&ordering=-pin`
        return getRequests(url)
    },
    addCommunityPost: function (data) {
        const url = `community/post/`
        return postRequests(url, data)
    },
    getPostComments: function (postId) {
        const url = `community/comment/?post=${postId}`
        return getRequests(url)
    },
    getPostLikes: function (postId) {
        const url = `community/post-like/?post=${postId}`
        return getRequests(url)
    },
    getPostShares: function (postId) {
        const url = `community/post/${postId}/share-user/`
        return getRequests(url)
    },
    deleteCommunityPost: function (postId) {
        const url = `community/post/${postId}`
        return deleteRequests(url)
    },
    deleteCommunityPostComment: function (commentId) {
        const url = `community/comment/${commentId}`
        return deleteRequests(url)
    },
    deleteCommunityPostCommentReply: function (replyId) {
        const url = `community/reply/${replyId}`
        return deleteRequests(url)
    },
    pinUnpinPost: function (arg, postId) {
        const url = `community/post/${postId}/pin/`
        return postRequests(url, arg)
    },

    getUserDetail: function (id) {
        const url = `user/${id}/`
        return getRequests(url)
    },
    savePayment: function (id, data) {
        return postRequests(`user/save-payment/${id}`, data)
    },
    updateCommunityPost: function (arg, postId) {
        const url = `community/post/${postId}/`
        return patchRequests(url, arg)
    },
    getUserFollowers: function (userId) {
        const url = `community/followers/?limit=100&following=${userId}`
        return getRequests(url)
    },
    getUserFollowing: function (userId) {
        const url = `community/following/?limit=100&user=${userId}`
        return getRequests(url)
    },
    getFreeLessons: function (data) {
        const url = 'lessons/freelesson/list/'
        return getRequests(url)
    },
    getLinks: function () {
        const url = 'link/'
        return getRequests(url)
    },
    getNotifications: function () {
        const url = 'students/newusernotification/list/'
        return getRequests(url)
    },
    addNotification: function (data) {
        const url = `students/newusernotification/list/`
        return postRequests(url, data)
    },
    addLink: function (data) {
        const url = `link/`
        return postRequests(url, data)
    },
    updateNotification: function (linkId, data) {
        const url = `students/newusernotification/list/update/`
        return postRequests(url, data)
    },
    deleteNotification: function (lessonId) {
        const url = 'students/newusernotification/list/' + lessonId + '/'
        return deleteRequests(url)
    },
    updateLink: function (linkId, data) {
        const url = `link/update/`
        return postRequests(url, data)
    },
    deleteLink: function (lessonId) {
        const url = 'link/' + lessonId + '/'
        return deleteRequests(url)
    },
    createLink: function (data) {
        const url = 'link/'
        return postRequests(url, data)
    },
    createDictionary: function (data) {
        const url = 'dictionary/'
        return postRequests(url, data)
    },
    getDictionary: function () {
        const url = 'dictionary/'
        return getRequests(url)
    },
    addDictionary: function (data) {
        const url = `dictionary/`
        return postRequests(url, data)
    },
    updateDictionary: function (DictionaryId, data) {
        const url = `dictionary/update/`
        return postRequests(url, data)
    },
    deleteDictionary: function (lessonId) {
        const url = 'dictionary/' + lessonId + '/'
        return deleteRequests(url)
    },
    getChatUser: function (data) {
        let url
        if (data) {
            url = `chat/get-admin-chats-members-v1?search=${data}`
        } else {
            url = `chat/get-admin-chats-members-v1/`
        }

        return getRequests(url)
    },

    getNextChatUsers: function (nextUrl) {
        const url = `${nextUrl}`
        return getLinkRequests(url)
    },
    addSaveChat: function (data) {
        const url = `chat/save-chat/`
        return postRequests(url, data)
    },
    getSaveChat: function () {
        const url = `chat/save-chat/`
        return getRequests(url)
    },
    getSaveChatList: function (data) {
        let url
        url = `chat/save-chat?search=${data}`

        return getRequests(url)
    },

    updateSaveChat: function (id, data) {
        const url = `chat/save-chat/${id}/`
        return putRequests(url, data)
    },
    deleteSaveChat: function (id) {
        const url = `chat/save-chat/${id}/`
        return deleteRequests(url)
    },
    getChatMessage: function (memberId) {
        const url = `chat/get-messages/?member_id=${memberId}`
        return getRequests(url)
    },
    getOnlineCount: function () {
        const url = `chat/total-online-count/`
        return getRequests(url)
    },
    postChatMessage: function (data) {
        const url = `chat/post-admin-message/`
        return postRequests(url, data)
    },

    getChatConfig: function () {
        const url = 'chat/chat-config/'
        return getRequests(url)
    },
    postChatConfig: function (data) {
        const url = `chat/chat-config/`
        return postRequests(url, data)
    },
    updateChatConfig: function (id, data) {
        const url = `chat/chat-config/${id}/`
        return patchRequests(url, data)
    },
    getMeetingLinks: function () {
        const url = `chat/meeting-link/`
        return getRequests(url)
    },
    deleteMeeting: function (id) {
        const url = `chat/meeting-link/${id}/`
        return deleteRequests(url)
    },
    postNewMeeting: function (data) {
        const url = `chat/meeting-link/`
        return postRequests(url, data)
    },
}

module.exports = RemoteServices
