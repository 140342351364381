import { useCallback, useEffect, useRef, useState } from 'react'

export function useMediaStream({
    autoSwitch = true,
    videoDeviceId,
    audioDeviceId,
} = {}) {
    const stream = useRef(null)
    const ref = useRef(null)

    const [isPlaying, setPlaying] = useState(false)
    const [isAudioMuted, setAudioMuted] = useState(false)
    const [isVideoMuted, setVideoMuted] = useState(false)

    const getDeviceOptions = useCallback((device) => {
        if (device === 'none' || device === false) return false
        if (device === null) return true

        return {
            deviceId: device,
        }
    }, [])

    const play = useCallback(async () => {
        if (stream.current) return

        stream.current =
            (await window.navigator?.mediaDevices.getUserMedia({
                video: getDeviceOptions(videoDeviceId),
                audio: getDeviceOptions(audioDeviceId),
            })) ?? null

        setPlaying(true)
        return stream.current
    }, [audioDeviceId, getDeviceOptions, videoDeviceId])

    const stop = useCallback(() => {
        setPlaying(false)
        if (stream.current) {
            const tracks = stream.current.getTracks()
            tracks.forEach(function (track) {
                track.stop()
            })
        }
        stream.current = null
    }, [])

    const restart = useCallback(async () => {
        stop()
        return await play()
    }, [play, stop])

    const muteAudio = useCallback(() => {
        setAudioMuted(true)
        if (stream.current) {
            const audioTracks = stream.current.getAudioTracks()
            audioTracks.forEach(function (track) {
                track.enabled = false
            })
        }
    }, [])

    const unMuteAudio = useCallback(() => {
        setAudioMuted(false)
        if (stream.current) {
            const audioTracks = stream.current.getAudioTracks()
            audioTracks.forEach(function (track) {
                track.enabled = true
            })
        }
    }, [])

    const muteVideo = useCallback(() => {
        setVideoMuted(true)
        if (stream.current) {
            const videoTracks = stream.current.getVideoTracks()
            videoTracks.forEach(function (track) {
                track.enabled = false
            })
        }
    }, [])

    const unMuteVideo = useCallback(() => {
        setVideoMuted(false)
        if (stream.current) {
            const videoTracks = stream.current.getVideoTracks()
            videoTracks.forEach(function (track) {
                track.enabled = true
            })
        }
    }, [])

    const pause = useCallback(() => {
        muteAudio()
        muteVideo()
    }, [muteAudio, muteVideo])

    const resume = useCallback(() => {
        unMuteAudio()
        unMuteVideo()
    }, [unMuteAudio, unMuteVideo])

    useEffect(() => {
        if (!ref.current) return

        ref.current.srcObject = stream.current
    }, [isPlaying, isVideoMuted])

    useEffect(() => {
        if (autoSwitch && stream.current) restart()
    }, [videoDeviceId, audioDeviceId, autoSwitch, restart])

    const toggleAudio = () => {
        switch (isAudioMuted) {
            case true:
                unMuteAudio()
                break
            case false:
                muteAudio()
                break
            default:
                return
        }
    }

    return {
        ref,
        stream,
        isPlaying,
        play,
        stop,
        restart,
        isAudioMuted,
        muteAudio,
        unMuteAudio,
        isVideoMuted,
        muteVideo,
        unMuteVideo,
        pause,
        resume,
        isPaused: isAudioMuted && isVideoMuted,
    }
}
