/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index.js'
import Login from 'views/examples/Login.js'
import Settings from 'views/examples/Settings.js'
import Assignments from 'views/examples/Assignments.js'
import Quizzes from 'views/examples/Quizzes'
import Courses from 'views/examples/Courses.js'
import Users from 'views/examples/Users.js'
import Articles from 'views/examples/Articles.js'
import Staff from 'views/examples/Staff.js'
import main from 'views/examples/leadstosales/main.js'
import Withdraw from 'views/examples/Withdraw.js'
import ReviewComments from 'views/examples/ReviewComments'
import ReviewRatings from 'views/examples/ReviewRatings'
import PaymentPage from 'views/examples/PaymentPage'
import CommunityFeed from 'views/examples/CommunityFeed'
import Links from 'views/examples/Link'
import Dictionary from 'views/examples/Dictionary'
import LessonNew from './views/examples/LessonNew'
import DeactivatedUsers from './views/examples/DeactivatedUser'
import Notification from './views/examples/Notification'
import ArticleNew from './views/examples/ArticleNew'
import FreeLessonNew from './views/examples/FreeLessonNew'
import CourseNew from './views/examples/CourseNew'
import Leads from './views/examples/Leads'
import Vacancy from './views/examples/Vacancy'
import ReferralList from './views/examples/ReferralList'
import AmountSetup from './views/examples/AmountSetup'
import Lessons from './views/examples/Lessons'
import PromoCode from './views/examples/PromoCode'
import FreeLessons from './views/examples/FreeLessons'
import LeadsFollowup from 'views/examples/LeadsFollowup'
import Messages from 'views/examples/Messages'
import VideoCall from 'views/examples/VideoCall'
import TeacherSupport from 'views/examples/TeacherSupport'
import GroupCall from 'views/examples/GroupCall'

var routes = [
    {
        path: '/index',
        name: 'Dashboard',
        icon: 'ni ni-tv-2 text-primary',
        component: Index,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/Assignments',
        name: 'Assignments',
        icon: 'ni ni-planet text-blue',
        component: Assignments,
        layout: '/admin',
        user: [2, 3, 4, 5, 9],
    },
    {
        path: '/LessonOld',
        name: 'LessonOld',
        icon: 'ni ni-bullet-list-67 text-blue',
        component: Lessons,
        layout: '/admin',
        user: [2, 3, 5],
    },
    {
        path: '/Lesson',
        name: 'Lessons',
        icon: 'ni ni-bullet-list-67 text-blue',
        component: LessonNew,
        layout: '/admin',
        user: [2, 3, 5],
    },
    {
        path: '/FreeLesson',
        name: 'Free Lessons',
        icon: 'ni ni-money-coins text-blue',
        component: FreeLessonNew,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/Courses',
        name: 'Courses',
        icon: 'ni ni-books text-blue',
        component: Courses,
        layout: '/admin',
        user: [2, 3],
    },
    // {
    //     path: '/CoursesNew',
    //     name: 'Courses New',
    //     icon: 'ni ni-books text-blue',
    //     component: CourseNew,
    //     layout: '/admin',
    //     user: [2, 3, 4]
    // },
    {
        path: '/Quizzes',
        name: 'Quizzes',
        icon: 'ni ni-atom text-blue',
        component: Quizzes,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/ReviewComments',
        name: 'Review Comments',
        icon: 'ni ni-ui-04 text-blue',
        component: ReviewComments,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/ReviewRatings',
        name: 'Review App Ratings',
        icon: 'ni ni-app text-blue',
        component: ReviewRatings,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/CommunityFeed',
        name: 'Community Feed',
        icon: 'ni ni-image text-blue',
        component: CommunityFeed,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },

    {
        path: '/students',
        name: 'Students',
        icon: 'ni ni-hat-3 text-blue',
        component: Users,
        layout: '/admin',
        user: [2, 3, 4, 5, 8, 9, 10],
    },
    {
        path: '/leadsfollowup',
        name: 'Leads Followup',
        icon: 'ni ni-time-alarm text-blue',
        component: LeadsFollowup,
        layout: '/admin',
        user: [2, 3, 4, 5, 8, 9],
    },
    {
        path: '/link',
        name: 'Links',
        icon: 'ni ni-ruler-pencil text-blue',
        component: Links,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/dictionary',
        name: 'Dictionary',
        icon: 'ni ni-collection text-blue',
        component: Dictionary,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/staffs',
        name: 'Staffs',
        icon: 'ni ni-single-02 text-blue',
        component: Staff,
        layout: '/admin',
        user: [3],
    },
    {
        path: '/deactivatedUsers',
        name: 'Deactivated Users',
        icon: 'ni ni-circle-08 text-blue',
        component: DeactivatedUsers,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/withdraw',
        name: 'Withdraw Requests',
        icon: 'ni ni-tie-bow text-blue',
        component: Withdraw,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/leadstosales',
        name: 'Campaign',
        icon: 'ni ni-money-coins text-blue',
        component: main,
        layout: '/admin',
        user: [2, 3, 4, 5, 9],
    },
    {
        path: '/Settings',
        name: 'Config',
        icon: 'ni ni-settings-gear-65 text-blue',
        component: Settings,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/Notification',
        name: 'Notification',
        icon: 'ni ni-bell-55 text-blue',
        component: Notification,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/Payment',
        name: 'Payment',
        icon: 'ni ni-cart text-blue',
        component: PaymentPage,
        layout: '/admin',
        user: [2, 3],
    },

    {
        path: '/Articles',
        name: 'Articles',
        icon: 'ni ni-collection text-blue',
        component: ArticleNew,
        layout: '/admin',
        user: [2, 3, 4, 5, 9],
    },
    {
        path: '/leads',
        name: 'Leads',
        icon: 'ni ni-books text-blue',
        component: Leads,
        layout: '/admin',
        user: [2, 3],
    },

    {
        path: '/messages',
        name: 'Messages',
        icon: 'ni ni-email-83 text-primary',
        component: Messages,
        layout: '/admin',
        user: [2, 3, 4, 5, 8, 9],
    },
    {
        path: '/call',
        name: 'Call',
        icon: 'ni ni-email-83 text-primary',
        component: VideoCall,
        layout: '/admin',
        user: [2, 3, 4, 5, 8, 9],
    },
    {
        path: '/group-call',
        name: 'Group Call',
        icon: 'ni ni-email-83 text-primary',
        component: GroupCall,
        layout: '/admin',
        user: [2, 3, 4, 5, 8, 9],
    },

    {
        path: '/vacancy',
        name: 'Vacancy',
        icon: 'ni ni-books text-blue',
        component: Vacancy,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/referralIds',
        name: 'Referral Lists',
        icon: 'ni ni-app text-blue',
        component: ReferralList,
        layout: '/mlm',
        user: [2, 3],
    },
    {
        path: '/promoCode',
        name: 'Promo Code',
        icon: 'ni ni-app text-blue',
        component: PromoCode,
        layout: '/admin',
        user: [2, 3, 4, 5],
    },
    {
        path: '/amountSetup',
        name: 'Amount Setup',
        icon: 'ni ni-settings-gear-65 text-blue',
        component: AmountSetup,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/meeting',
        name: 'Meeting',
        icon: 'ni ni-headphones text-blue',
        component: TeacherSupport,
        layout: '/admin',
        user: [2, 3],
    },
    {
        path: '/login',
        name: 'Logout',
        icon: 'ni ni-user-run text-yellow',
        component: Login,
        layout: '/auth',
        user: [2, 3, 4, 5, 8, 9],
    },
    // {
    //     path: '/videocall',
    //     name: 'videocall',
    //     icon: '',
    //     component: UDPModel,
    //     layout: '/udplayot',
    //     user: [2, 3, 4, 5, 8, 9]
    // },

    // {
    //   path: "/logout",
    //   name: "Logout",
    //   icon: "ni ni-user-run text-red",
    //   component: Logout,
    //   layout: "/auth",
    //   user: 2
    // }
    // ,
    // {
    //   path: "/register",
    //   name: "Register",
    //   icon: "ni ni-circle-08 text-pink",
    //   component: Register,
    //   layout: "/auth"
    // }
]
export default routes
