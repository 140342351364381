import React from 'react'
import SimplePeer from 'simple-peer'
import { useLocation } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import { useMediaStream } from './use-media-stream'

export const useWebrtc = () => {
    const {
        ref: localVideoRef,
        stream: localStream,
        play,
        unMuteAudio,
        unMuteVideo,
        muteAudio,
        muteVideo,
        isVideoMuted,
        isAudioMuted,
    } = useMediaStream({
        audioDeviceId: undefined,
        videoDeviceId: undefined,
    })

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const meetingId = queryParams.get('join')
    const userId = JSON.parse(localStorage.getItem('user'))?.id

    React.useEffect(() => {
        play()
    }, [play])

    const peersRef = React.useRef(new Map())

    const [peerList, setPeerList] = React.useState([])

    const wsUrl = `${process.env.REACT_APP_SOCKET_URL}meeting/${meetingId}-${userId}/room/`

    const ws = useWebSocket(
        wsUrl,
        {
            onOpen: () => console.log('Socket connection established'),
            onClose: () => console.log('Socket connection closed'),
        },
        Boolean(userId) && Boolean(meetingId)
    )

    React.useEffect(() => {
        const message = ws.lastJsonMessage

        if (!message) return

        switch (message.type) {
            case 'participants':
                handleConnectedPeers(message.peerIds)
                break
            case 'new_participant':
                handleNewPeer(message.peerId)
                break
            case 'participant_left':
                handlePeerDisconnect(message.peerId)
                break
            case 'signal':
                handleSignal({
                    sender: message.sender,
                    recipient: message.recipient,
                    signal: message.signal,
                })
                break
            default:
                break
        }
    }, [ws.lastJsonMessage])

    const handleConnectedPeers = (peerIds) => {
        peerIds.forEach((peerId) => {
            if (
                !peersRef.current.has(peerId) &&
                String(peerId) !== String(userId) &&
                !isPeerIdNull(peerId)
            ) {
                createPeer(peerId, true)
            }
        })
    }

    const handleNewPeer = (peerId) => {
        if (
            !peersRef.current.has(peerId) &&
            String(peerId) !== String(userId) &&
            !isPeerIdNull(peerId)
        ) {
            createPeer(peerId, false)
        }
    }

    const handlePeerDisconnect = (peerId) => {
        const peerData = peersRef.current.get(peerId)
        if (peerData) {
            peerData.peer.destroy()
            peersRef.current.delete(peerId)
            updatePeerList()
        }
    }

    const handleSignal = ({ sender, recipient, signal }) => {
        if (String(recipient) !== String(userId)) return

        const peerData = peersRef.current.get(sender)
        if (peerData) {
            peerData.peer.signal(signal)
        }
    }

    const createPeer = (peerId, initiator) => {
        const peer = new SimplePeer({
            initiator,
            stream: localStream.current || undefined,
            trickle: false,
        })

        peer.on('signal', (data) => {
            ws.sendJsonMessage({
                type: 'signal',
                recipient: String(peerId),
                sender: String(userId),
                signal: data,
            })
        })

        peer.on('stream', (remoteStream) => {
            peersRef.current.set(peerId, {
                id: peerId,
                peer,
                stream: remoteStream,
            })
            updatePeerList()
        })

        peer.on('connect', () => {
            console.log(`Peer ${peerId} connected successfully`)
            updatePeerList()
        })

        peer.on('error', (err) => {
            console.error(
                `Error with peer ${peerId}, initiator ${initiator}:`,
                err
            )
            peersRef.current.delete(peerId)
            updatePeerList()
        })

        peer.on('close', () => {
            peersRef.current.delete(peerId)
            peer.destroy()
            updatePeerList()
        })

        peersRef.current.set(peerId, {
            id: peerId,
            peer,
            stream: null,
        })
        updatePeerList()
    }

    const updatePeerList = () => {
        setPeerList(Array.from(peersRef.current.values()))
    }

    const isPeerIdNull = (peerId) => {
        return peerId === 'null' || !peerId
    }

    const toggleAudioVideo = (isMuted, type) => {
        if (!localStream.current) return
        const peersRefNotEmpty = peersRef.current.size
        const oldTrack = localStream.current
            .getTracks()
            .find((track) => track.kind === type)
        switch (isMuted) {
            case true:
                switch (type) {
                    case 'audio':
                        unMuteAudio()
                        break
                    case 'video':
                        unMuteVideo()
                        break
                    default:
                        break
                }
                if (!peersRefNotEmpty) {
                    peersRef.current.forEach(({ peer }) => {
                        if (!localStream.current || !oldTrack) return

                        const newTrack = localStream.current
                            .getTracks()
                            .find((track) => track.kind === type)

                        if (newTrack) {
                            peer.replaceTrack(
                                oldTrack,
                                newTrack,
                                localStream.current
                            )
                        }
                    })
                }
                break
            case false:
                switch (type) {
                    case 'audio':
                        muteAudio()
                        break
                    case 'video':
                        muteVideo()
                        break
                    default:
                        break
                }
                if (!peersRefNotEmpty) {
                    peersRef.current.forEach(({ peer }) => {
                        if (!localStream.current || !oldTrack) return

                        const newTrack = localStream.current
                            .getTracks()
                            .find((track) => track.kind === type)

                        if (newTrack) {
                            peer.replaceTrack(
                                oldTrack,
                                newTrack,
                                localStream.current
                            )
                        }
                    })
                }
                break
            default:
                break
        }
    }

    const toggleVideo = () => {
        toggleAudioVideo(isVideoMuted, 'video')
    }

    const toggleAudio = () => {
        toggleAudioVideo(isAudioMuted, 'audio')
    }

    return {
        localVideoRef,
        peersRef,
        peerList,
        toggleAudio,
        toggleVideo,
        isVideoMuted,
        isAudioMuted,
    }
}
