var utils = {
    hasAccess(layoutUser, userType) {
        let id = 1

        if (userType === 'Admin') {
            id = 2
            return layoutUser.includes(id)
        }
        if (userType.toUpperCase() === 'SUPERUSER') {
            id = 3
            return layoutUser.includes(id)
        }
        if (userType === 'Pro Ext') {
            id = 4
            return layoutUser.includes(id)
        }
        if (userType === 'Pro Int') {
            id = 5
            return layoutUser.includes(id)
        }
        if (userType === 'Basic') {
            id = 8
            return layoutUser.includes(id)
        }
        if (userType === 'Medium') {
            id = 9
            return layoutUser.includes(id)
        }
        if (userType === 'Teacher') {
            id = 10
            return layoutUser.includes(id)
        }

        return false // Default case if userType is neither 'Admin' nor 'Superuser'
    },
}

module.exports = utils
